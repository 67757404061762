import './App.css';
import maridialogo from './maridia_logo.png';
import SocialFlow from "./comps/SocialFlow"
import { Lightbox } from 'yet-another-react-lightbox';
import * as React from "react";
import { useState } from 'react';
import "yet-another-react-lightbox/plugins/captions.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Images from './comps/Images'
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/styles.css";

// Image imports
import { a_24_04_20_bremen } from './assets/image_data/data';
import { a_24_04_18_kopenhamn } from './assets/image_data/data';
import { a_24_01_12_enskede } from './assets/image_data/data';
import { a_23_08_18_fmangel } from './assets/image_data/data';
import { a_23_10_14_sktn } from './assets/image_data/data';
import { a_23_05_26_punkfest } from './assets/image_data/data';
import { a_23_09_29_antisocial } from './assets/image_data/data';
import { a_23_10_13_sofia_c } from './assets/image_data/data';
import { pre_show_photos } from './assets/image_data/data';

// Background image
import backgroundImage from './assets/website_bg_mar.jpg';


function App() {
  // const [open, setOpen] = React.useState(false);
  // const captionsRef = React.useRef(null);
  const [index, setIndex] = useState(-1)
  const [index2, setIndex2] = useState(-1)
  const [index3, setIndex3] = useState(-1)
  const [index4, setIndex4] = useState(-1)
  const [index5, setIndex5] = useState(-1)
  const [index6, setIndex6] = useState(-1)
  const [index7, setIndex7] = useState(-1)
  const [index8, setIndex8] = useState(-1)
  const [index9, setIndex9] = useState(-1)

  return (
    <div className="App" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <p>
      <img className='boxbunnypichome' src={maridialogo} alt="Box Bunny Small Black Logo"></img>
      </p>

      <p className='textstandard'>
      Grim Post-Hardcore from Sweden.<br></br>
      Debut EP "Mouth of Ruin" out now.
      </p>
      <br></br>
      <p className='rubrik'>
        CONTACT
      </p>
      <p className='textstandard'>
        maridia.info@gmail.com
      </p>
      <SocialFlow />

      <br></br>
      <br></br>
      <p className='rubrik'>
        GALLERY
      </p>

      <br></br>
      <p className='textstandard'>
      Void Over Weyhe, Bremen DE 2024-04-20
      </p>
      <Images data={a_24_04_20_bremen} onClick={(currentIndex) => setIndex9(currentIndex)} />

      <Lightbox
        slides={a_24_04_20_bremen}
        index={index9}
        open={index9 >= 0}
        close={() => setIndex9(-1)}
        plugins={[Slideshow, Thumbnails]}
      />

      <br></br>
      <p className='textstandard'>
      Ungdomshuset, Copenhagen DK 2024-04-18
      </p>
      <Images data={a_24_04_18_kopenhamn} onClick={(currentIndex) => setIndex8(currentIndex)} />

      <Lightbox
        slides={a_24_04_18_kopenhamn}
        index={index8}
        open={index8 >= 0}
        close={() => setIndex8(-1)}
        plugins={[Slideshow, Thumbnails]}
      />

      <br></br>
      <p className='textstandard'>
        Gamla Enskede Bryggeri 2024-01-12
      </p>
      <Images data={a_24_01_12_enskede} onClick={(currentIndex) => setIndex2(currentIndex)} />

      <Lightbox
        slides={a_24_01_12_enskede}
        index={index2}
        open={index2 >= 0}
        close={() => setIndex2(-1)}
        plugins={[Slideshow, Thumbnails]}
      />

      <br></br>
      <div>
      <p className='textstandard'>
        SKTN, Gothenburg 2023-10-14
      </p>
      </div>

      <Images data={a_23_10_14_sktn} onClick={(currentIndex) => setIndex3(currentIndex)} />

      <Lightbox
        slides={a_23_10_14_sktn}
        index={index3}
        open={index3 >= 0}
        close={() => setIndex3(-1)}
        plugins={[Slideshow, Thumbnails]}
      />
      <br></br>

      <br></br>
<p className='textstandard'>
        Sofia Common, Stockholm 2023-10-13
      </p>
      <Images data={a_23_10_13_sofia_c} onClick={(currentIndex) => setIndex4(currentIndex)} />

      <Lightbox
        slides={a_23_10_13_sofia_c}
        index={index4}
        open={index4 >= 0}
        close={() => setIndex4(-1)}
        plugins={[Slideshow, Thumbnails]}
      />
      <br></br>

      <br></br>
      <p className='textstandard'>
        Antisocial Festival, Stockholm 2023-09-29
      </p>
      <Images data={a_23_09_29_antisocial} onClick={(currentIndex) => setIndex6(currentIndex)} />

      <Lightbox
        slides={a_23_09_29_antisocial}
        index={index6}
        open={index6 >= 0}
        close={() => setIndex6(-1)}
        plugins={[Slideshow, Thumbnails]}
      />
      <br></br>

      <br></br>
      <p className='textstandard'>
        Fredagsmangel, Jakobsberg Stockholm 2023-08-18
      </p>
      <Images data={a_23_08_18_fmangel} onClick={(currentIndex) => setIndex(currentIndex)} />

      <Lightbox
        slides={a_23_08_18_fmangel}
        index={index}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Slideshow, Thumbnails]}
      />
      <br></br>

      <br></br>
      <p className='textstandard'>
        Punkfest, Umea 2023-05-26
      </p>
      <Images data={a_23_05_26_punkfest} onClick={(currentIndex) => setIndex5(currentIndex)} />

      <Lightbox
        slides={a_23_05_26_punkfest}
        index={index5}
        open={index5 >= 0}
        close={() => setIndex5(-1)}
        plugins={[Slideshow, Thumbnails]}
      />
      <br></br>


      <br></br>
      <p className='textstandard'>
        Pre Show Photos
      </p>
      <Images data={pre_show_photos} onClick={(currentIndex) => setIndex7(currentIndex)} />

      <Lightbox
        slides={pre_show_photos}
        index={index7}
        open={index7 >= 0}
        close={() => setIndex7(-1)}
        plugins={[Slideshow, Thumbnails]}
      />
      <br></br>
      <br></br>
      <br></br>
      <br></br>


      
    </div>
  );
}

export default App;
