export const a_24_01_12_enskede  = [
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3595-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3614-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3628.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3672.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3690-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3694-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3851-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3864-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3874-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3916-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3953-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC3968-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC4098-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC4120-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC4141-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC4153-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC4163-Enhanced-NR.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-01-12-enskede/_DFC4182-Enhanced-NR.jpg'},
    ];

export const a_23_05_26_punkfest  = [
    { src: 'https://maridia-images.s3.amazonaws.com/23-05-26-punkfest/2023-05-27 03.02.24-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-05-26-punkfest/2023-05-27 03.05.27-1-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-05-26-punkfest/2023-05-27 03.05.57-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-05-26-punkfest/2023-05-27 03.06.07.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-05-26-punkfest/received_1643570129442369.jpg'},
    ];

export const a_23_08_18_fmangel  = [
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5770.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5862.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5866.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5886.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5899.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5929.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_5965-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_6045.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_6066.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_6080.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_6106.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-08-18-fmangel/IMG_6197.jpg'},
    ];

export const a_23_09_29_antisocial  = [
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7020-3.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7032-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7036.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7061.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7074.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7078.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7111.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7113.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7124.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7127.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7189.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-09-29-antisocial/IMG_7525.jpg'},
    ];

export const a_23_10_13_sofia_c  = [
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-13-sofia-c/Photo 2023-10-14, 12 52 35-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-13-sofia-c/Photo 2023-10-15, 12 00 07 (1)-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-13-sofia-c/Photo 2023-10-15, 12 00 07 (4)-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-13-sofia-c/Photo 2023-10-15, 12 00 07-2.jpg'},
    ];

export const a_23_10_14_sktn  = [
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-14-sktn/Foto 2023-10-14 21 45 33-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-14-sktn/Foto 2023-10-14 21 46 53-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-14-sktn/Foto 2023-10-14 21 46 56-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-14-sktn/Foto 2023-10-14 21 47 09-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/23-10-14-sktn/Foto 2023-10-14 21 49 26-2.jpg'},
    ];

export const pre_show_photos  = [
    { src: 'https://maridia-images.s3.amazonaws.com/pre-show-photos/2023-05-26 16.14.13-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/pre-show-photos/2023-10-14 21.11.57-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/pre-show-photos/IMG_0002.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/pre-show-photos/IMG_5743.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/pre-show-photos/IMG_6995.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/pre-show-photos/Photo 2023-10-14, 12 52 35-2.jpg'},
    ];

export const a_24_04_20_bremen  = [
    { src: 'https://maridia-images.s3.amazonaws.com/24-04-20-bremen/IMG_0768-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-04-20-bremen/IMG_3477-2.jpg'},
    { src: 'https://maridia-images.s3.amazonaws.com/24-04-20-bremen/IMG_3489-2.jpg'},
    ];

export const a_24_04_18_kopenhamn  = [
    { src: 'https://maridia-images.s3.amazonaws.com/24-04-18-kopenhamn/IMG_3427-2.jpg'}
    ];