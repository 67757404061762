import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faBandcamp } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'


export default function SocialFollow() {
  return (

    <div class="social-container">

    <a href="https://www.instagram.com/maridia.official/"
      className="instagram social">
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>

    <a href="https://www.facebook.com/maridia.music"
        className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>

      <a href="https://maridia-official.bandcamp.com"
        className="bandcamp social">
        <FontAwesomeIcon icon={faBandcamp} size="2x" />
      </a>

      <a href="https://www.youtube.com/@Maridia.Official" 
      className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>

      <a href="https://open.spotify.com/artist/2hywhtc2VcUpCp2hZLA1FE?si=Xltfgs_aTBCwFxO19Z0QeQ"
        className="spotify social">
        <FontAwesomeIcon icon={faSpotify} size="2x" />
      </a>

</div>
  );
}